<template>
  <div class="agent">
    <div class="top">
      <div class="querybox">
        <el-row :gutter="20">
          <el-col :span="7">
            <p>代理商昵称:</p>
            <el-input
              v-model="queryParams.agentNickname"
              placeholder="请输入代理商昵称"
            ></el-input>
          </el-col>
          <el-col :span="7">
            <p>佣金来源:</p>
            <el-select
              v-model="queryParams.saleAgentCommissionSource"
              placeholder="请选择佣金来源"
              clearable
            >
              <el-option
                v-for="item in getDictDatas(
                  DICT_TYPE.SALE_AGENT_COMMISSION_SOURCE
                )"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="7">
            <p>佣金状态:</p>
            <el-select
              v-model="queryParams.commissionStatus"
              placeholder="请选择佣金状态"
              clearable
            >
              <el-option
                v-for="item in getDictDatas(DICT_TYPE.COMMISSION_STATUS)"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="7" style="margin-top: 10px">
            <p>时间筛选:</p>
            <el-date-picker
              v-model="value"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              @change="timeChange"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="7" style="margin-top: 10px">
            <p>显示状态:</p>
            <el-select
              v-model="queryParams.showStatus"
              placeholder="请选择显示状态"
            >
              <el-option
                v-for="item in showList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="7">
            <p>订单编号:</p>
            <el-input
              v-model="queryParams.orderNo"
              placeholder="请输入订单编号"
            ></el-input>
          </el-col>
        </el-row>
      </div>
      <div class="btnbox">
        <el-button
          type="primary"
          icon="el-icon-refresh-right"
          class="gray-btn"
          @click="resetFn"
          >重置</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-search"
          class="blue-btn"
          @click="search"
          >查询</el-button
        >
      </div>
    </div>
    <div class="content">
      <el-table
        :data="tableData"
        tooltip-effect="dark"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column prop="agentNickname" label="代理商昵称">
        </el-table-column>
        <el-table-column prop="agentPhone" label="代理商电话">
        </el-table-column>
        <el-table-column prop="saleAgentCommissionSource_dictText" label="来源">
        </el-table-column>
        <el-table-column prop="money" label="用户支付金额">
          <template slot-scope="{ row }"> ￥ {{ row.money }} </template>
        </el-table-column>
        <el-table-column prop="commissionMoney" label="佣金金额">
          <template slot-scope="{ row }">
            ￥ {{ row.commissionMoney }}
          </template>
        </el-table-column>
        <el-table-column prop="commissionStatus" label="佣金状态">
          <template slot-scope="{ row }">
            <span
              :style="{
                color:
                  row.commissionStatus === 0
                    ? '#06D0A9'
                    : row.commissionStatus === 1
                    ? '#6F28ED'
                    : '#FE4B46',
              }"
              >{{ row.commissionStatus_dictText }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="orderNo" label="订单编号" min-width="100">
        </el-table-column>
        <el-table-column prop="createdTime" label="时间" min-width="100">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button
              class="bluetext-btn"
              type="text"
              @click="goEdit(row)"
              style="margin-right: 5px"
              >修改状态
            </el-button>
            <!-- <el-button class="blue-btn" v-if="row.">显示</el-button> -->
            <!-- <el-button class="red-btn">隐藏</el-button> -->
            <el-popconfirm
              title="是否显示该佣金？"
              popper-class="customerdel-popover"
              @confirm="cancelOrder(row, 0)"
              v-if="Number(row.showStatus) === 1"
            >
              <el-button class="textblue-btn" slot="reference">显示</el-button>
            </el-popconfirm>
            <el-popconfirm
              title="是否隐藏该佣金？"
              popper-class="customerdel-popover"
              @confirm="cancelOrder(row, 1)"
              v-if="Number(row.showStatus) === 0"
            >
              <el-button class="textred-btn" slot="reference">隐藏</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <pagination
        v-show="total > queryParams.pageSize"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="init"
      />
    </div>
    <!-- 修改状态 -->
    <el-dialog
      :visible.sync="editVisible"
      title="修改状态"
      width="30%"
      :before-close="handleeditClose"
      destroy-on-close
      :show-close="false"
    >
      <div slot="title" class="title">
        <p>修改状态</p>
        <span @click="handleeditClose"><i class="el-icon-close"></i></span>
      </div>
      <div class="status-box">
        <span>选择修改状态</span>
        <p
          v-for="item in statusList"
          :key="item.value"
          :class="statusValue === item.value ? 'active' : ''"
          @click="changeStatus(item.value)"
        >
          {{ item.label }}
          <img
            v-if="statusValue === item.value"
            src="../../../assets/checked.png"
            alt=""
          />
        </p>
      </div>
      <div class="btn-box">
        <el-button type="primary" class="gray-btn" @click="handleeditClose"
          >取消</el-button
        >
        <el-button type="primary" class="blue-btn" @click="editStatus"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCommission,
  editCommissionStatus,
  setShowStatus,
} from "@/api/goods";
export default {
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        agentNickname: "",
        startTime: "",
        endTime: "",
        saleAgentCommissionSource: "",
        commissionStatus: "",
        showStatus: 0,
        orderNo: "",
      },
      tableData: [],
      total: 0, //总数
      editVisible: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value: "",
      statusList: [
        {
          value: 0,
          label: "待结算",
        },
        {
          value: 1,
          label: "已结算",
        },
        {
          value: 2,
          label: "不可结算",
        },
      ],
      showList: [
        {
          value: 0,
          label: "显示",
        },
        {
          value: 1,
          label: "隐藏",
        },
      ],
      statusValue: 0,
      statusInfo: {},
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    //获取代理商列表
    async init() {
      const res = await getCommission(this.queryParams);
      this.tableData = res.result.records;
      this.total = res.result.total;
    },
    // 查询
    search() {
      this.queryParams.pageNum = 1;
      this.init();
    },
    //重置
    resetFn() {
      this.queryParams.agentNickname = "";
      this.queryParams.startTime = "";
      this.queryParams.endTime = "";
      this.queryParams.saleAgentCommissionSource = "";
      this.queryParams.commissionStatus = "";
      this.queryParams.showStatus = 0;
      this.queryParams.pageNum = 1;
      this.queryParams.orderNo = "";
      this.init();
    },
    // 修改状态
    async goEdit(value) {
      this.statusInfo = value;
      this.statusValue = value.commissionStatus;
      this.editVisible = true;
    },
    handleeditClose() {
      this.editVisible = false;
    },
    changeStatus(value) {
      this.statusValue = value;
    },
    async editStatus() {
      await editCommissionStatus({
        id: this.statusInfo.id,
        commissionStatus: this.statusValue,
      });
      this.$message.success("修改佣金状态成功！");
      this.init();
      this.editVisible = false;
    },
    timeChange(event) {
      // console.log(event);
      if (event) {
        this.queryParams.startTime = this.$moment(event[0])
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.queryParams.endTime = this.$moment(event[1])
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
      } else {
        this.queryParams.startTime = "";
        this.queryParams.endTime = "";
      }
      console.log(this.queryParams);
    },
    // 显示隐藏
    async cancelOrder(item, status) {
      const params = {
        showStatus: status,
        id: item.id,
      };
      await setShowStatus(params);
      // this.$refs[`${text}-popover-${index}`].doClose();
      this.init();
    },
  },
};
</script>
<style lang="scss" scoped>
.agent {
  .btnbox-agent {
    display: flex;
    justify-content: flex-end;
    .el-button {
      width: 100px;
    }
  }
  .top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #0000000d;
    .querybox {
      width: 90%;
      margin-bottom: 20px;
      ::v-deep .el-row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .el-col {
          display: flex;
          align-items: center;
          p {
            color: #4e5969;
            line-height: 22px;
            margin-right: 16px;
            white-space: nowrap;
          }
          .el-input,
          .el-range-editor {
            width: 260px;
          }
          input::-webkit-input-placeholder {
            /* WebKit browsers */
            color: #86909c;
          }
          .el-input__inner,
          .el-range-input {
            background: #f7f8fa;
            border: none;
            color: #86909c;
          }
          .tabul {
            margin: 16px 0;
            li {
              margin-right: 24px;
              white-space: nowrap;
            }
          }
        }
      }
    }
    .btnbox {
      display: flex;
      .el-button {
        padding: 11px 18px;
        // width: 84px;
        width: fit-content;
        margin-right: 12px;
        height: fit-content;
      }
    }
  }
  .content {
    padding-top: 20px;
    .logo {
      display: flex;
      justify-content: center;
      img {
        width: 50px;
        height: 50px;
        border-radius: 5px;
      }
    }
  }
  ::v-deep .el-dialog__body {
    padding: 22px 43px 27px 50px;
    .status-box {
      width: 100%;
      span {
        display: block;
        font-size: 15px;
        color: #333333;
        line-height: 21px;
        margin-bottom: 16px;
      }
      p {
        cursor: pointer;
        width: 100%;
        padding: 12px 22px;
        box-sizing: border-box;
        font-size: 15px;
        color: #333333;
        line-height: 21px;
        background: #f5f6f6;
        margin-bottom: 22px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
          width: 20px;
          height: 14px;
        }
      }
      .active {
        background: rgba(94, 131, 245, 0.29);
        color: #5e83f5;
      }
    }
    .btn-box {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .el-button {
        width: 105px;
        border-radius: 6px;
        padding: 11px 0;
      }
    }
  }
}
</style>
